export const monthNames = [
  "Enero",
  "Febrero",
  "Marzp",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Augosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
